import React from 'react'

const NoPage = () => {
  return (
    <div>
        <h1>404 - No Page</h1>
    </div>
  )
}

export default NoPage
