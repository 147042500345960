import React from "react";

const About = () => {
  return (
    <div>
      <h1 className="text-3xl">About</h1>
    </div>
  );
};

export default About;
